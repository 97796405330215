import React from "react";
import "../styles/global.scss";
import CustomSanityImage from "./helper/CustomSanityImage";
import {Link} from "gatsby";
import MyPortableText from "./MyPortableText";

const ServicesHero25 = ({page}) => {
    console.log(page)
    return (
        <div className="min-h-screen">
            <div className={"h-[60vh] relative"}>
                <div className="absolute bottom-0 left-0 w-full h-[50%] bg-gradient-to-b from-transparent to-white z-10 opacity-60"></div>
                <CustomSanityImage imageData={page?.image}/>
            </div>
            <div className="bg-orange p-base laptop:p-doublebase">
                <div className="laptop:flex">
                    <h2 className={"text-white uppercase text-3xl phonexl:text-4xl !leading-[.9] laptop:text-5xl wide:text-6xl w-[33%]"}>
                        {page?.headline}
                    </h2>
                    <div className="flex items-end w-[66%] mt-base laptop:mt-0">
                        <h2 className={"uppercase text-3xl phonexl:text-4xl !leading-[.9] laptop:text-5xl wide:text-6xl"}>
                            {page?.headline2}
                        </h2>
                        <h2 className={"px-doublebase uppercase text-3xl phonexl:text-4xl !leading-[.9] laptop:text-5xl wide:text-6xl"}>
                            &
                        </h2>
                        <h2 className={"hidden laptop:block uppercase text-3xl phonexl:text-4xl !leading-[.9] laptop:text-5xl wide:text-6xl"}>
                            {page?.headline3}
                        </h2>
                    </div>
                    <h2 className={"laptop:hidden w-[66%] mt-base uppercase text-3xl phonexl:text-4xl !leading-[.9] laptop:text-5xl wide:text-6xl"}>
                        {page?.headline3}
                    </h2>
                </div>
                <div className="laptop:flex mt-base laptop:mt-doublebase">
                    <div className="laptop:w-[33%]">
                        <Link to={page?.button?.link} className="btn bold btnservices25 filled white uppercase">{page?.button?.title}</Link>
                    </div>
                    <div className={"laptop:w-[66%] mt-base laptop:mt-0"}>
                        <MyPortableText value={page?._rawText}></MyPortableText>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default ServicesHero25
