import React, {useEffect, useRef, useState} from "react";
import "../styles/global.scss";
import {Link} from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {Autoplay, Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";


import {useBreakpoint} from "gatsby-plugin-breakpoints";
import TestimonialSlide from "./TestimonialSlide";


const TestimonialSwiper = ({testimonials}) => {

    const [showImage, setShowImage] = useState(false);
    const [showImage2, setShowImage2] = useState(false);

    const breakpoints = useBreakpoint();

    return (
        <Swiper
            spaceBetween={0}
            slidesPerView={breakpoints.sm ? 1 : 2}
            speed={1500}
            navigation={true} modules={[Navigation, Autoplay]} autoplay={{
            delay: 10000,
            disableOnInteraction: true,
        }} loop={true}
        >
            {testimonials.map((el) => (
                <SwiperSlide>
                    <TestimonialSlide el={el}></TestimonialSlide>
                </SwiperSlide>
            ))
                }
        </Swiper>
    )
}
export default TestimonialSwiper
