import React from "react";
import Layout from "../Layout";
import Head from "../Head";
import Landing from "../landing2024/Landing";
import Schritte from "../landing2024/Schritte";
import Info from "../landing2024/Info";
import Warum from "../landing2024/Warum";
import Referenzen from "../landing2024/Referenzen";
import Newsletter from "../landing2024/Newsletter";
import Kontakt from "../landing2024/Kontakt";
import Marquee from "react-fast-marquee";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import TestimonialSwiper from "../TestimonialSwiper";
import LandingImage from "../LandingImage";
import ServicesHero25 from "../ServicesHero25";
import EniosolMarquee from "../Marquee";

const Service25 = ({service, old, settings}) => {

    return (
        <Layout settings={settings} service={true} landing={true} subfolder={true}>
            <Head title={service?.seo?.seo_title}/>


            {service?.pageBuilder?.map((section, index) => {
                if (section._type === 'serviceshero25') {
                    return <React.Fragment key={index}>
                        <ServicesHero25 page={section}></ServicesHero25>
                    </React.Fragment>
                } else if (section._type === 'servicessteps25') {
                    return <React.Fragment key={index}>
                        <div className="servicelinks__container pt-doublebase">
                            <h2 className="color-orange uppercase">
                                {old.servicesheadline}
                            </h2>
                            <div className="serviceroad__svg__container">
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">01</p>
                                        <div className="coloredring__container first">
                                            <div className="colorringimage pink">
                                                <GatsbyImage className="imageround" image={old.servicessteps[0].image.asset.gatsbyImageData} alt={old.servicessteps[0].title}></GatsbyImage>
                                            </div>
                                            <div className="road__verbindung-after pink"></div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[0].title}</p>
                                        <p className="small">{old.servicessteps[0].text}</p>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">02</p>
                                        <div className="coloredring__container">
                                            <div className="road__verbindung-before pink"></div>
                                            <div className="colorringimage pink">
                                                <GatsbyImage className="imageround" image={old.servicessteps[1].image.asset.gatsbyImageData} alt={old.servicessteps[1].title}></GatsbyImage>
                                            </div>
                                            <div className="road__verbindung-after pink"></div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[1].title}</p>
                                        <p className="small">{old.servicessteps[1].text}</p>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">03</p>
                                        <div className="coloredring__container">
                                            <div className="road__verbindung-before pink"></div>
                                            <div className="colorringimage pink">
                                                <GatsbyImage className="imageround" image={old.servicessteps[2].image.asset.gatsbyImageData} alt={old.servicessteps[2].title}></GatsbyImage>
                                            </div>
                                            <div className="road__verbindung-after pink"></div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[2].title}</p>
                                        <p className="small">{old.servicessteps[2].text}</p>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">04</p>
                                        <div className="coloredring__container last">
                                            <div className="road__verbindung-before pink"></div>
                                            <div className="colorringimage pink">
                                                <GatsbyImage className="imageround" image={old.servicessteps[3].image.asset.gatsbyImageData} alt={old.servicessteps[3].title}></GatsbyImage>
                                            </div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[3].title}</p>
                                        <p className="small">{old.servicessteps[3].text}</p>
                                    </div>
                                </div>
                                <div className="road__newrow__container">
                                    <div className="road__newrow pink">
                                        <div className="rightcorner"></div>
                                        <div className="middle"></div>
                                        <div className="leftcorner"></div>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">05</p>
                                        <div className="coloredring__container first">
                                            <div className="colorringimage pink">
                                                <GatsbyImage className="imageround" image={old.servicessteps[4].image.asset.gatsbyImageData} alt={old.servicessteps[4].title}></GatsbyImage>
                                            </div>
                                            <div className="road__verbindung-after pink"></div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[4].title}</p>
                                        <p className="small">{old.servicessteps[4].text}</p>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">06</p>
                                        <div className="coloredring__container">
                                            <div className="road__verbindung-before pink"></div>
                                            <div className="colorringimage pink">
                                                <GatsbyImage className="imageround" image={old.servicessteps[5].image.asset.gatsbyImageData} alt={old.servicessteps[5].title}></GatsbyImage>
                                            </div>
                                            <div className="road__verbindung-after pink"></div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[5].title}</p>
                                        <p className="small">{old.servicessteps[5].text}</p>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">07</p>
                                        <div className="coloredring__container">
                                            <div className="road__verbindung-before pink"></div>
                                            <div className="colorringimage pink">
                                                <GatsbyImage className="imageround" image={old.servicessteps[6].image.asset.gatsbyImageData} alt={old.servicessteps[6].title}></GatsbyImage>
                                            </div>
                                            <div className="road__verbindung-after green"></div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[6].title}</p>
                                        <p className="small">{old.servicessteps[6].text}</p>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">08</p>
                                        <div className="coloredring__container last">
                                            <div className="road__verbindung-before green"></div>
                                            <div className="colorringimage green">
                                                <GatsbyImage className="imageround" image={old.servicessteps[7].image.asset.gatsbyImageData} alt={old.servicessteps[7].title}></GatsbyImage>
                                            </div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[7].title}</p>
                                        <p className="small">{old.servicessteps[7].text}</p>
                                    </div>
                                </div>
                                <div className="road__newrow__container">
                                    <div className="road__newrow green">
                                        <div className="rightcorner"></div>
                                        <div className="middle"></div>
                                        <div className="leftcorner"></div>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">09</p>
                                        <div className="coloredring__container first">
                                            <div className="colorringimage green">
                                                <GatsbyImage className="imageround" image={old.servicessteps[8].image.asset.gatsbyImageData} alt={old.servicessteps[8].title}></GatsbyImage>
                                            </div>
                                            <div className="road__verbindung-after green"></div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[8].title}</p>
                                        <p className="small">{old.servicessteps[8].text}</p>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">10</p>
                                        <div className="coloredring__container">
                                            <div className="road__verbindung-before green"></div>
                                            <div className="colorringimage green">
                                                <GatsbyImage className="imageround" image={old.servicessteps[9].image.asset.gatsbyImageData} alt={old.servicessteps[9].title}></GatsbyImage>
                                            </div>
                                            <div className="road__verbindung-after green"></div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[9].title}</p>
                                        <p className="small">{old.servicessteps[9].text}</p>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">11</p>
                                        <div className="coloredring__container">
                                            <div className="road__verbindung-before green"></div>
                                            <div className="colorringimage green">
                                                <GatsbyImage className="imageround" image={old.servicessteps[10].image.asset.gatsbyImageData} alt={old.servicessteps[10].title}></GatsbyImage>
                                            </div>
                                            <div className="road__verbindung-after green"></div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[10].title}</p>
                                        <p className="small">{old.servicessteps[10].text}</p>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">12</p>
                                        <div className="coloredring__container last">
                                            <div className="road__verbindung-before green"></div>
                                            <div className="colorringimage green">
                                                <GatsbyImage className="imageround" image={old.servicessteps[11].image.asset.gatsbyImageData} alt={old.servicessteps[11].title}></GatsbyImage>
                                            </div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[11].title}</p>
                                        <p className="small">{old.servicessteps[11].text}</p>
                                    </div>
                                </div>
                                <div className="road__newrow__container">
                                    <div className="road__newrow green">
                                        <div className="rightcorner"></div>
                                        <div className="middle"></div>
                                        <div className="leftcorner"></div>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">13</p>
                                        <div className="coloredring__container first">
                                            <div className="colorringimage bluegrey">
                                                <GatsbyImage className="imageround" image={old.servicessteps[12].image.asset.gatsbyImageData} alt={old.servicessteps[12].title}></GatsbyImage>
                                            </div>
                                            <div className="road__verbindung-after bluegrey"></div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[12].title}</p>
                                        <p className="small">{old.servicessteps[12].text}</p>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">14</p>
                                        <div className="coloredring__container">
                                            <div className="road__verbindung-before bluegrey"></div>
                                            <div className="colorringimage bluegrey">
                                                <GatsbyImage className="imageround" image={old.servicessteps[13].image.asset.gatsbyImageData} alt={old.servicessteps[13].title}></GatsbyImage>
                                            </div>
                                            <div className="road__verbindung-after bluegrey"></div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[13].title}</p>
                                        <p className="small">{old.servicessteps[13].text}</p>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">15</p>
                                        <div className="coloredring__container">
                                            <div className="road__verbindung-before bluegrey"></div>
                                            <div className="colorringimage bluegrey">
                                                <GatsbyImage className="imageround" image={old.servicessteps[14].image.asset.gatsbyImageData} alt={old.servicessteps[14].title}></GatsbyImage>
                                            </div>
                                            <div className="road__verbindung-after bluegrey"></div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[14].title}</p>
                                        <p className="small">{old.servicessteps[14].text}</p>
                                    </div>
                                </div>
                                <div className="road__element__wrapper">
                                    <div className="road__element">
                                        <p className="large">16</p>
                                        <div className="coloredring__container last">
                                            <div className="road__verbindung-before bluegrey"></div>
                                            <div className="colorringimage bluegrey">
                                                <GatsbyImage className="imageround" image={old.servicessteps[15].image.asset.gatsbyImageData} alt={old.servicessteps[15].title}></GatsbyImage>
                                            </div>
                                        </div>
                                        <p className="small bold uppercase">{old.servicessteps[15].title}</p>
                                        <p className="small">{old.servicessteps[15].text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                } else if(section._type === 'servicestestimonials25'){
                    return <React.Fragment key={index}>
                        <TestimonialSwiper testimonials={old.testimonial}></TestimonialSwiper>
                    </React.Fragment>
                } else if(section._type === 'serviceslinks25'){
                    return <React.Fragment key={index}>
                        <div className="servicelinks__container">
                            <h2 className="color-orange uppercase">
                                {old.serviceoverviewheadline}
                            </h2>
                            <div className="servicelinks__wrapper">
                                {old?.service?.map(el => (
                                    <div className="service__einzel__container">
                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={el.title}></GatsbyImage>
                                        <p className="large">{el.title}</p>
                                        <p className="small">{el.text}</p>
                                        <Link to={el.slug} className="btn filled orange bold smalltext small hover-moveup">MEHR
                                            INFO</Link>
                                    </div>
                                ))}
                            </div>
                            <div className="servicelinks__wrapper feature">
                                <div className="service__feature__container">
                                    <div className="service__feature__imgs">
                                        <GatsbyImage image={old.service2.image.asset.gatsbyImageData} alt={old.service2.title}></GatsbyImage>
                                        <GatsbyImage image={old.service2.image2.asset.gatsbyImageData} alt={old.service2.title} className="desktop"></GatsbyImage>

                                    </div>
                                    <p className="large">{old.service2.title}</p>
                                    <p className="small">{old.service2.text}</p>
                                    <Link to={old.service2.slug} className="btn filled orange bold smalltext small hover-moveup">JETZT KONTAKTIEREN</Link>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                } else if(section._type === 'servicesende25'){
                    return <React.Fragment key={index}>
                        <div className="service__testimonial__container color-whiteonblack">
                            <div className="service__testi__left">
                                <p className="large nomargin">Benötigen Sie eine individuelle Beratung? <br/>Wir sind gerne jederzeit für Sie da!</p>
                            </div>
                            <div className="service__testi__right arrowbtn__wrapper">
                                {section?.buttons?.map((button, index) => {
                                    return <Link to={button?.link} className="mb-[5px] btn !p-[5px] flex items-center smalltext bold filled uppercase !w-full max-w-[400px]" style={{background: button?.color?.hex}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className={"!h-[45px] !w-auto"} width="53" height="53" viewBox="0 0 53 53">
                                            <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                                <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                                    <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                                    <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                                </g>
                                                <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                                    <g id="Group_13" data-name="Group 13">
                                                        <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                                        <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <p className=" bold largesection ml-[10px]">{button?.title}</p>
                                    </Link>
                                })}
                            </div>
                        </div>
                    </React.Fragment>
                } else if(section._type === 'servicesreferenzen25'){
                    return <React.Fragment key={index}>
                        <div className="reference__container">
                            <Marquee gradient={false} className="marqueeWrapper color-orange">
                                <div className="marqueeText">
                                    <span className="marqueeText__inner ">REFERENZEN</span>
                                    <span className="marqueeText__inner ">REFERENZEN</span>
                                    <span className="marqueeText__inner ">REFERENZEN</span>
                                    <span className="marqueeText__inner ">REFERENZEN</span>
                                    <span className="marqueeText__inner ">REFERENZEN</span>
                                    <span className="marqueeText__inner ">REFERENZEN</span>
                                    <span className="marqueeText__inner ">REFERENZEN</span>
                                    <span className="marqueeText__inner ">REFERENZEN</span>
                                    <span className="marqueeText__inner ">REFERENZEN</span>
                                </div>
                            </Marquee>
                            <div className="reference__wrapper ">
                                {old.referenzen.map(el => (
                                    <div className="referenzen__einzel__container">
                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={el.title}></GatsbyImage>
                                        <p className="large mobile-small mobile-bold">{el.title}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="referenze__images__container">
                                <Marquee gradient={false} className="bgbluegrey marqueeWrapper imagemarquee">
                                    <div className="marqueeText">
                                        {old.endimages.map(el => (
                                            <span className="marqueeText__inner"><GatsbyImage image={el.asset.gatsbyImageData} alt="REFERENZEN"></GatsbyImage></span>
                                        ))}
                                        {old.endimages.map(el => (
                                            <span className="marqueeText__inner"><GatsbyImage image={el.asset.gatsbyImageData} alt="REFERENZEN"></GatsbyImage></span>
                                        ))}
                                    </div>
                                </Marquee>
                            </div>
                        </div>
                    </React.Fragment>
                }
            })}
        </Layout>
    )
}
export default Service25
