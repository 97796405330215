import React from "react";
import {graphql} from "gatsby";
import Service25 from "../components/pageComponent/Service25";

export const query = graphql`
    {
        allSanityService {
            edges {
                node {
                    image1 {
                        asset {
                            gatsbyImageData
                        }
                    }
                    image2 {
                        asset {
                            gatsbyImageData
                        }
                    }
                    image3 {
                        asset {
                            gatsbyImageData
                        }
                    }

                    serviceoverviewheadline
                    servicesheadline
                    testimonial {
                        text
                        title
                        ort
                        image{asset{gatsbyImageData}}
                    }
                    referenzen {
                        image {
                            asset {
                                gatsbyImageData
                            }
                        }
                        title
                    }
                    service {
                        image {
                            asset {
                                gatsbyImageData
                            }
                        }
                        text
                        title
                        slug
                    }
                    service2 {
                        text
                        title
                        slug
                        image {
                            asset {
                                gatsbyImageData
                            }
                        }
                        image2 {
                            asset {
                                gatsbyImageData
                            }
                        }
                    }
                    endimages {
                        asset {
                            gatsbyImageData
                        }
                    }
                    servicessteps{
                        title
                        text
                        image{asset{gatsbyImageData}}

                    }
                }
            }
        }
        allSanityServicespage25{
            edges{
                node{
                    pageBuilder {
                        ... on SanityServiceshero25 {
                            _type
                            image {
                                alt
                                asset {
                                    gatsbyImageData
                                }
                            }
                            button {
                                title
                                link
                            }
                            _rawText
                            headline
                            headline2
                            headline3
                        }
                        ... on SanityServicessteps25 {
                            _type
                        }
                        ... on SanityServicestestimonials25 {
                            _type
                        }
                        ... on SanityServicesreferenzen25 {
                            _type
                        }
                        ... on SanityServicesende25 {
                            _type
                            title
                            buttons{
                                title
                                link
                                color{
                                    hex
                                }
                            }
                        }
                        ... on SanityServiceslinks25 {
                            _type
                        }
                    }
                    seo{
                        seo_title
                    }
                }
            }
        }
        allSanitySettings {
            edges {
                node {
                    instalink
                    fblink
                    linkedinlink
                }
            }
        }
    }
`

const Service = ({data}) => {

    const service = data.allSanityService.edges[0].node;
    const service25 = data.allSanityServicespage25.edges[0].node;

    return (
        <Service25 service={service25} old={service} settings={data?.allSanitySettings?.edges[0]?.node}/>
    )
}

export default Service;


/*
{service.servicessteps.map((el, index) => (
                        <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">{addLeadingZeros(index+1, 2)}</p>
                            <ColorRingImage className="colorringimage" color={el.color}>
                                <GatsbyImage image={el.image.asset.gatsbyImageData} alt={el.title}></GatsbyImage>
                            </ColorRingImage>
                            <p className="small bold uppercase">{el.title}</p>
                            <p className="small">{el.text}</p>
                        </div>
                        </div>
                    ))}
 */
